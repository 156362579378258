@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'MyFont';
  src: url('../src/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
}

body {
  background: #070D24;
  font-family: 'MyFont';

}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

.TitleOne {
  position: absolute;
  top: 128px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99999;
  font-size: 105px;
  font-weight: 900;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
  text-shadow: 0 0 3px #6C45DB, 0 0 5px #6C45DB;
  border: 2px #6C45DB;
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #6C45DB;
  font-family: 'MyFont';
}

.Star1 {
  position: absolute;
  left: 5px;
  top: -30px;
}

.Star2 {
  position: absolute;
  right: 10px;
  filter: blur(2px);
}

.TitleContent {
  position: relative;
  width: 700px;
  display: block;
  margin: auto;
}

.TitleTiny {
  border: 0;
  -webkit-text-stroke-width: 0;
  font-size: 25px;
  /* margin-top: -80px; */
  width: 700px;
  display: block;
  margin: auto;
  color: #6C45DB;
  text-shadow: none;
  height: 50px;
  line-height: 50px;
}

.Copyright {
  position: absolute;
  bottom: 40px;
  right: 40px;
  font-size: 13px
}

.Copyleft {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 13px;
  color: #fff;
  width: 200px;
}

@media screen and (max-width: 768px) {

  .TitleOne {
    position: absolute;
    top: 128px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999;
    font-size: 50px;
    font-weight: 900;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    text-shadow: 0 0 3px #6C45DB, 0 0 5px #6C45DB;
    border: 2px #6C45DB;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #6C45DB;
    font-family: 'MyFont';
    text-align: center;
  }

  .Star1 {
    position: absolute;
    left: 5px;
    top: -30px;
  }

  .Star2 {
    position: absolute;
    right: 10px;
    filter: blur(2px);
  }

  .TitleContent {
    position: relative;
    width: auto;
    display: block;
    margin: auto;
  }

  .TitleTiny {
    border: 0;
    -webkit-text-stroke-width: 0;
    font-size: 18px;
    /* margin-top: -80px; */
    width: auto;
    display: block;
    margin: auto;
    color: #6C45DB;
    text-shadow: none;
    height: 30px;
    line-height: 30px;
  }

  .Copyright {
    position: absolute;
    bottom: 90px;
    width: 100%;
    font-size: 13px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .Copyleft {
    position: absolute;
    bottom: 30px;
    width: 100%;
    font-size: 13px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}